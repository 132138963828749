import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textAlign } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import { Field, FastField } from 'formik'
import get from 'lodash/get'

import { responsive } from 'components/ThemeProvider/theme';

import Flex from './Flex';
import Box from './Box';
import Text from './Text';
import InputError from './InputError'

const TextInput = styled.input`
  font-family: inherit;
  width: 100%;
  padding: 0.25em;
  border: none;
  outline: none;
  border-bottom: 1px solid lightGray;
  border-radius: 0;
  font-size: 1.25em;
  ${textAlign};
  &::placeholder {
    color: ${themeGet('colors.gray')};
  }
`;

const LabelText = props => <Text fontSize="1.375em" color="darkPurple" fontWeight="bold" {...props} />

const OptionItem = ({ type, name, value, title, disabled, labelFontSize, fast, readOnly, ...props }) => (
  <Flex as="label" alignItems="center" {...props}>
    {createElement(fast ? FastField : Field, {
      type,
      name,
      value: String(value),
      component: ({ field }) => (
        <Box
          {...field}
          id={name}
          width="1em"
          type={type}
          disabled={disabled || readOnly}
          as="input"
        />
      )
    })}
    {title && (
      <Box ml="0.5em">
        <LabelText fontSize={labelFontSize}>{title}</LabelText>
      </Box>
    )}
  </Flex>
)

const Input = ({
  label,
  options,
  labelWidth,
  name,
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  onKeyUp,
  onFocus,
  error,
  type,
  min,
  max,
  textAlign,
  labelFontSize,
  note,
  step,
  readOnly,
  fast,
  textarea,
  ...props
}) => {
  const l = options && options.length
  return Boolean(l) ? (
    <Box as="fieldset" position="relative" {...props}>
      <Flex>
        {label && (
          <Box as="legend" fontSize="1.125rem" color="darkPurple" width={labelWidth} mr="1em">
            <LabelText fontSize={labelFontSize}>{label}</LabelText>
          </Box>
        )}
        <Flex alignItems="center" flexWrap="wrap">
          {options.map(({ title, id }, i) => (
            <OptionItem
              key={name + i}
              type={type}
              name={name}
              value={id}
              title={title}
              pr={i < l - 1 && responsive('1em', '1.625em')}
              opacity={disabled && 0.5}
              labelFontSize={labelFontSize}
              disabled={disabled}
              readOnly={readOnly}
              fast={fast}
            />
          ))}
        </Flex>
      </Flex>
      <InputError error={error} />
    </Box>
  ) : (
    <Flex as={label ? 'label' : 'div'} pt="1em" width={1} {...props}>
      {label && (
        <Box fontSize="1.125rem" mr="0.75em" color="darkPurple" width={labelWidth}>
          <LabelText fontSize={labelFontSize}>{label}</LabelText>
        </Box>
      )}
      <Box flex="1" textAlign={textAlign} position="relative">
        {createElement(TextInput, {
          as: textarea ? 'textarea' : 'input',
          id: name,
          name,
          placeholder,
          disabled,
          type,
          min,
          max,
          step,
          readOnly,
          onKeyUp,
          onChange,
          onBlur,
          onFocus,
          value,
          rows: 4,
        })}
        {note && <Box position="absolute" top="100%" left="0%" mt="0.25em" fontSize={responsive('0.875rem', '0.875rem')}>({note})</Box>}
        <InputError note={note} error={error} />
      </Box>
    </Flex>
  )
};

Input.propTypes = {
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.displayName = 'Input';

Input.defaultProps = {
  flexDirection: 'colunm',
}

Input.Field = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <Input
        flexDirection={props.type !== 'radio' && responsive('column', 'row')}
        alignItems={responsive('auto', 'center')}
        error={get(form.touched, name) && get(form.errors, name)}
        {...props}
        {...field}
      />
    )}
  </Field>
)

Input.FastField = ({ name, ...props }) => (
  <FastField name={name}>
    {({ field, form }) => (
      <Input
        flexDirection={props.type !== 'radio' && responsive('column', 'row')}
        alignItems={responsive('auto', 'center')}
        error={get(form.touched, name) && get(form.errors, name)}
        fast
        {...props}
        {...field}
      />
    )}
  </FastField>
)


export default Input;
